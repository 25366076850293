import React from 'react';
import PropTypes from 'prop-types';
import Span from './Span';

const PP001P46_2 = ({ form, addNextForm, updateForm }) => {
  const FormComponent = () => {
    // TODO try to dynamically render type, to avoid switch case
    switch (form.type) {
      case 'span':
        return <Span form={form} addNextForm={addNextForm} updateForm={updateForm} />;
      default:
        throw new Error(`Unknown type (${form.type}).`);
    }
  };

  return (
    <section>
      <FormComponent />
    </section>
  );
};

PP001P46_2.propTypes = {
  form: PropTypes.object.isRequired,

  updateForm: PropTypes.func.isRequired,
};

export default PP001P46_2;
