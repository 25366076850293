import React from 'react';
import PropTypes from 'prop-types';
import { POLES_GSS_ZERO } from '../Input/Poles';
import { GEAR_RAIL_NO } from '../Input/GearRail';
import { HOLDER_NO } from '../Input/Holder';

export const ThreeD4GateSelfSupportingSecondPagePdf = ({ values, response }) => {
  if (values.width <= 5800 && response.productionMaterials.saw.rail) {
    return (
      <div>
        <p><b>BOK</b></p>
        <p>
          Całkowita długość bramy:
          {' '}
          {response.productionMaterials.saw.rail.size.value + 20}
          {' '}
          mm
        </p>
      </div>
    );
  }
  if (values.width <= 7499) {
    const firstGateHalf = values.gearRail === GEAR_RAIL_NO ? response.productionMaterials.saw.topFrontHorizontalFrameWidth.size.value : response.productionMaterials.saw.bottomZProfile.size.value + 200;
    const secondGateHalf = response.productionMaterials.saw.backRail.size.value;
    return (
      <div>
        <p><b>BOK</b></p>
        <p>
          1
          {'. '}
          {firstGateHalf > secondGateHalf ? 'Dłuższa ' : 'Krótsza '}
          połówka bramy:
          {' '}
          {firstGateHalf}
          {' '}
          mm
        </p>
        <p>
          2
          {'. '}
          {secondGateHalf > firstGateHalf ? 'Dłuższa ' : 'Krótsza '}
          połówka bramy:
          {' '}
          {secondGateHalf}
          {' '}
          mm
        </p>
      </div>
    );
  }
  const firstGateHalf = response.productionMaterials.saw.topFrontHorizontalFrameWidth.size.value;
  const secondGateHalf = response.productionMaterials.saw.backRail.size.value;
  return (
    <div>
      <p><b>BOK</b></p>
      <p>
        1.
        {' '}
        {firstGateHalf > secondGateHalf ? 'Dłuższa ' : 'Krótsza '}
        połówka bramy:
        {' '}
        {firstGateHalf}
        {' '}
        mm
      </p>
      <p>
        2.
        {' '}
        {secondGateHalf > firstGateHalf ? 'Dłuższa ' : 'Krótsza '}
        połówka bramy:
        {' '}
        {secondGateHalf}
        {' '}
        mm
      </p>
    </div>
  );
};

const GateSelfSupportingResponse = ({
  values, hdg, response, isPdf,
}) => {
  const laser = Object.keys(response.productionMaterials.laser).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.laser[key].name}</td>
      <td>{response.productionMaterials.laser[key].material}</td>
      <td>
        {response.productionMaterials.laser[key].amount.value}
        {' '}
        {response.productionMaterials.laser[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.laser[key].size.value}
        {' '}
        {response.productionMaterials.laser[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const saw = Object.keys(response.productionMaterials.saw).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.saw[key].name}</td>
      <td>{response.productionMaterials.saw[key].material}</td>
      <td>
        {response.productionMaterials.saw[key].amount.value}
        {' '}
        {response.productionMaterials.saw[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.saw[key].size.value}
        {' '}
        {response.productionMaterials.saw[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const studding = response.productionMaterials.studding ? Object.keys(response.productionMaterials.studding).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.studding[key].name}</td>
      <td>{response.productionMaterials.studding[key].material}</td>
      <td>
        {response.productionMaterials.studding[key].amount.value}
        {' '}
        {response.productionMaterials.studding[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.studding[key].size.value}
        {' '}
        {response.productionMaterials.studding[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
          <td />
        </>
      )}
    </tr>
  )) : null;

  const polesInConcrete = () => {
    if (hdg) {
      if (parseInt(values.poles) !== POLES_GSS_ZERO) {
        if (response.productionMaterials.saw.polesInConcrete.size.value <= 3000) {
          return (
            <>
              <p><b>OTWORY TECHNOLOGICZNE NA SŁUPACH DO WBETONOWANIA</b></p>
              <p>
                <b>Otwór 1</b>
                {': '}
                od góry 10 mm na mniejszej ściance
              </p>
            </>
          );
        }
        return (
          <>
            <p><b>OTWORY TECHNOLOGICZNE NA SŁUPACH DO WBETONOWANIA</b></p>
            <p>
              <b>Otwór 1</b>
              {': '}
              od góry 10 mm na mniejszej ściance
              <>
                {' '}
                |
                {' '}
                <b>Otwór 2</b>
                {': '}
                od dołu 10 mm na mniejszej ściance
              </>
            </p>
          </>
        );
      }
    } return null;
  };

  const polesOnHolder = () => {
    if (hdg) {
      if (values.holder !== HOLDER_NO) {
        if (response.productionMaterials.saw.polesOnHolder.size.value <= 3000) {
          return null;
        }
        return (
          <>
            <p><b>OTWORY TECHNOLOGICZNE NA SŁUPACH NA MARCE</b></p>
            <p>
              <b>Otwór 1</b>
              {': '}
              od góry 10 mm na mniejszej ściance
            </p>
          </>
        );
      }
    } return null;
  };

  const GearRail = () => {
    if (response.productionMaterials?.gearRail) {
      if (response.productionMaterials.gearRail.gearRailAmount !== 'NIE DOTYCZY' && response.productionMaterials.gearRail.gearRail !== '#VALUE!' && response.productionMaterials.gearRail.gearRailAmount > 0) {
        return (
          <table className="table">
            <thead>
              <tr>
                <th colSpan={isPdf ? '7' : '5'}>MAGAZYN - ZBROJENIE</th>
              </tr>
              <tr>
                <th className="responseThSingular">Lp.</th>
                <th className="responseThName">Nazwa</th>
                <th className="responseThMaterial">Materiał</th>
                <th className="responseThQuantity">Ilość</th>
                <th className="responseThMeasure">Wymiar</th>
                {isPdf && (
                  <>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <th className="responseThSaws" />
                    <th className="responseThD">MAGAZYN</th>
                  </>
                )}
              </tr>
            </thead>
            {values.width <= 5800 && (
              <tbody>
                <tr>
                  <td>1</td>
                  <td>{response.productionMaterials.gearRail.gearRail}</td>
                  <td>Listwa zębata 8x30x1005</td>
                  <td>
                    {response.productionMaterials.gearRail.gearRailAmount}
                    {' '}
                    szt
                  </td>
                  <td>
                    1005 mm
                  </td>
                  {isPdf && (
                    <>
                      <td />
                      <td className="tdCenter">
                        <div className="pdfSquare" />
                      </td>
                    </>
                  )}
                </tr>
              </tbody>
            )}
            {values.width > 5800 && (
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Listwa zębata</td>
                  <td>Listwa zębata 8x30x1005</td>
                  <td>
                    {response.productionMaterials.gearRail.gearRailAmount}
                    {' '}
                    szt
                  </td>
                  <td>
                    1005 mm
                  </td>
                  {isPdf && (
                    <>
                      <td />
                      <td className="tdCenter">
                        <div className="pdfSquare" />
                      </td>
                    </>
                  )}
                </tr>
                {(response.productionMaterials.gearRail.cutGearRailAmount !== 'NIE DOTYCZY' && response.productionMaterials.gearRail.gearRail !== '#VALUE!') && (
                  <tr>
                    <td>2</td>
                    <td>Listwa zębata docinana</td>
                    <td>Listwa zębata 8x30x1005</td>
                    <td>
                      {response.productionMaterials.gearRail.cutGearRailAmount}
                    </td>
                    <td>
                      DOCINANA
                    </td>
                    {isPdf && (
                      <>
                        <td />
                        <td className="tdCenter">
                          <div className="pdfSquare" />
                        </td>
                      </>
                    )}
                  </tr>
                )}
              </tbody>
            )}
          </table>
        );
      }
      return null;
    } return null;
  };

  return (
    <div>
      {(values.width <= 5800 && response.productionMaterials.info.gateIsRectangle && response.productionMaterials.info.gateIsRectangle === 'TAK')
        && (
          <p>
            <b>Czy brama prostokątna?</b>
            {' '}
            {response.productionMaterials.info.gateIsRectangle}
          </p>
        )}
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>LASER - MATERIAŁY DO WYPALENIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
            <>
              <th>{' '}</th>
              <th className="responseThSaws">3D</th>
            </>
            )}
          </tr>
        </thead>
        <tbody>
          {laser}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>PIŁA - MATERIAŁY DO WYCIĘCIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
            <>
              <th className="responseThSaws">PIŁA</th>
              <th className="responseThD">2D</th>
            </>
            )}
          </tr>
        </thead>
        <tbody>
          {saw}
          {values.width <= 5800 && response.productionMaterials.gearRail && response.productionMaterials.gearRail?.cutGearRailAmount && (
            <tr>
              <td>{saw.length + 1}</td>
              <td>{response.productionMaterials.gearRail.cutGearRail}</td>
              <td>Listwa zębata 8x30x1005</td>
              <td>
                {response.productionMaterials.gearRail.cutGearRailAmount}
                {' '}
                szt
              </td>
              <td>
                {response.productionMaterials.gearRail.cutGearRailSize}
                {' '}
                mm
              </td>
              {isPdf && (
                <>
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
              )}
            </tr>
          )}
          {values.width > 5800 && (response.productionMaterials?.gearRail && response.productionMaterials.gearRail.cutGearRailAmount !== 'NIE DOTYCZY') && (
            <tr>
              <td>{saw.length + 1}</td>
              <td>Listwa zębata</td>
              <td>Listwa zębata 8x30x1005</td>
              <td>1 szt</td>
              <td>DOCINANA</td>
              {isPdf && (
                <>
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
              )}
            </tr>
          )}
        </tbody>
      </table>
      {polesInConcrete()}
      {polesOnHolder()}
      {hdg && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>NABIJANIE</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              <th className="responseThMeasure">Wymiar</th>
              {isPdf && (
              <>
                <th className="responseThSaws">NABIJANIE</th>
                <th>{' '}</th>
              </>
              )}
            </tr>
          </thead>
          <tbody>
            {studding}
          </tbody>
        </table>
      )}
      <GearRail />
      {!isPdf && <ThreeD4GateSelfSupportingSecondPagePdf values={values} response={response} />}
    </div>
  );
};

GateSelfSupportingResponse.propTypes = {
  response: PropTypes.object.isRequired,
};

export default GateSelfSupportingResponse;
