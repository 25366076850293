import React from 'react';
import PropTypes from 'prop-types';
import { RECTANGLE_YES } from 'ResponseConstants';
import { GEAR_RAIL_NO } from '../Input/GearRail';

export const P302PB002GateSelfSupportingSecondPagePdf = ({ values, response, isPdf }) => {
  const GearRail = () => {
    const gearRailSetting = () => {
      if (values.width <= 5800) {
        return (
          <tbody>
            <tr>
              <td>1</td>
              <td>{response.productionMaterials.gearRail.gearRail}</td>
              <td>Listwa zębata 8x30x1005</td>
              <td>
                {response.productionMaterials.gearRail.gearRailAmount}
                {' '}
                szt
              </td>
              <td>
                1005 mm
              </td>
              {isPdf && (
                <>
                  <td />
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
              )}
            </tr>
          </tbody>
        );
      } return (
        <tbody>
          <tr>
            <td>1</td>
            <td>Listwa zębata</td>
            <td>Listwa zębata 8x30x1005</td>
            <td>
              {response.productionMaterials.gearRail.gearRailAmount}
              {' '}
              szt
            </td>
            <td>
              1005 mm
            </td>
            {isPdf && (
              <>
                <td />
                <td className="tdCenter">
                  <div className="pdfSquare" />
                </td>
              </>
            )}
          </tr>
          {(response.productionMaterials.gearRail.cutGearRailAmount !== 'NIE DOTYCZY' && response.productionMaterials.gearRail.gearRail !== '#VALUE!') && (
            <tr>
              <td>2</td>
              <td>Listwa zębata docinana</td>
              <td>Listwa zębata 8x30x1005</td>
              <td>
                {response.productionMaterials.gearRail.cutGearRailAmount}
              </td>
              <td>
                DOCINANA
              </td>
              {isPdf && (
                <>
                  <td />
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
              )}
            </tr>
          )}
        </tbody>
      );
    };
    if (response.productionMaterials?.gearRail) {
      if (response.productionMaterials.gearRail.gearRailAmount !== 'NIE DOTYCZY' && response.productionMaterials.gearRail.gearRail !== '#VALUE!' && response.productionMaterials.gearRail.gearRailAmount > 0) {
        return (
          <table className="table">
            <thead>
              <tr>
                <th colSpan={isPdf ? '7' : '5'}>MAGAZYN - ZBROJENIE</th>
              </tr>
              <tr>
                <th className="responseThSingular">Lp.</th>
                <th className="responseThName">Nazwa</th>
                <th className="responseThMaterial">Materiał</th>
                <th className="responseThQuantity">Ilość</th>
                <th className="responseThMeasure">Wymiar</th>
                {isPdf && (
                  <>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <th className="responseThSaws" />
                    <th className="responseThD">MAGAZYN</th>
                  </>
                )}
              </tr>
            </thead>
            {gearRailSetting}
          </table>
        );
      }
      return null;
    } return null;
  };

  const InfoForOffice = () => {
    if (values.width <= 5800 && response.productionMaterials.saw.rail) {
      return (
        <div>
          <p><b>BOK</b></p>
          <p>
            Całkowita długość bramy:
            {' '}
            {response.productionMaterials.saw.rail.size.value + 20}
            {' '}
            mm
          </p>
        </div>
      );
    } if (values.width <= 7500) {
      return (
        <div>
          <p><b>BOK</b></p>
          <p>
            1
            {'. '}
            Dłuższa połówka bramy:
            {' '}
            {values.gearRail === GEAR_RAIL_NO ? response.productionMaterials.saw.topFrontHorizontalFrameWidth.size.value : response.productionMaterials.saw.bottomZProfile.size.value + 200}
            {' '}
            mm
          </p>
          <p>
            2
            {'. '}
            Krótsza połówka bramy:
            {' '}
            {response.productionMaterials.saw.backRail.size.value}
            {' '}
            mm
          </p>
        </div>
      );
    } return null;
  };

  return (
    <>
      {isPdf && (
        <>
          <div>
            <p>
              Ilość pól z modułem:
              {' '}
              {response.productionMaterials.info.areaAmount.value}
              {' '}
              szt
            </p>
          </div>
          <div>
            <p>
              Wysokość pola [H]:
              {' '}
              {response.productionMaterials.info.areaHeight.value}
              {' '}
              mm
            </p>
          </div>
          <div>
            <p>
              Szerokość pola [L]:
              {' '}
              {response.productionMaterials.info.areaWidth.value}
              {' '}
              mm
            </p>
          </div>
          <div className="illustration">
            <div className="illustrationSquareGate" />
            <p className="illustrationHeightSymbolGate">H</p>
            <p className="illustrationWidthSymbolGate">L</p>
          </div>
        </>
      )}
      <GearRail />
      <InfoForOffice />
    </>
  );
};

const GateSelfSupportingResponse = ({ values, response, isPdf }) => {
  const angles = Object.keys(response.productionMaterials.angles).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.angles[key].name}</td>
      <td>{response.productionMaterials.angles[key].material}</td>
      <td>
        {response.productionMaterials.angles[key].amount.value}
        {' '}
        {response.productionMaterials.angles[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.angles[key].size.value}
        {' '}
        {response.productionMaterials.angles[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));
  const laser = Object.keys(response.productionMaterials.laser).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.laser[key].name}</td>
      <td>{response.productionMaterials.laser[key].material}</td>
      <td>
        {response.productionMaterials.laser[key].amount.value}
        {' '}
        {response.productionMaterials.laser[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.laser[key].size.value}
        {' '}
        {response.productionMaterials.laser[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));
  const saw = Object.keys(response.productionMaterials.saw).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.saw[key].name}</td>
      <td>{response.productionMaterials.saw[key].material}</td>
      <td>
        {response.productionMaterials.saw[key].amount.value}
        {' '}
        {response.productionMaterials.saw[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.saw[key].size.value}
        {' '}
        {response.productionMaterials.saw[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const gallantry = Object.keys(response.productionMaterials.gallantry).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.gallantry[key].name}</td>
      <td>{response.productionMaterials.gallantry[key].material}</td>
      <td>
        {response.productionMaterials.gallantry[key].amount.value}
        {' '}
        {response.productionMaterials.gallantry[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.gallantry[key].size.value}
        {' '}
        {response.productionMaterials.gallantry[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
          <td />
        </>
      )}
    </tr>
  ));

  const InfoForOffice = () => {
    if (values.width <= 5800 && response.productionMaterials.saw.rail) {
      return (
        <div>
          <p><b>BOK</b></p>
          <p>
            Całkowita długość bramy:
            {' '}
            {response.productionMaterials.saw.rail.size.value + 20}
            {' '}
            mm
          </p>
        </div>
      );
    }
    const firstGateHalf = values.gearRail === GEAR_RAIL_NO ? response.productionMaterials.saw.topFrontHorizontalFrameWidth.size.value : response.productionMaterials.saw.bottomZProfile.size.value + 200;
    const secondGateHalf = response.productionMaterials.saw.backRail.size.value;

    if (values.width <= 7500) {
      return (
        <div>
          <p><b>BOK</b></p>
          <p>
            1
            {'. '}
            {firstGateHalf > secondGateHalf ? 'Dłuższa ' : 'Krótsza '}
            połówka bramy:
            {' '}
            {firstGateHalf}
            {' '}
            mm
          </p>
          <p>
            2
            {'. '}
            {secondGateHalf > firstGateHalf ? 'Dłuższa ' : 'Krótsza '}
            połówka bramy:
            {' '}
            {secondGateHalf}
            {' '}
            mm
          </p>
        </div>
      );
    } return null;
  };

  const GearRail = () => {
    if (response.productionMaterials?.gearRail) {
      if (response.productionMaterials.gearRail.gearRailAmount !== 'NIE DOTYCZY' && response.productionMaterials.gearRail.gearRail !== '#VALUE!' && response.productionMaterials.gearRail.gearRailAmount > 0) {
        return (
          <table className="table">
            <thead>
              <tr>
                <th colSpan={isPdf ? '7' : '5'}>MAGAZYN - ZBROJENIE</th>
              </tr>
              <tr>
                <th className="responseThSingular">Lp.</th>
                <th className="responseThName">Nazwa</th>
                <th className="responseThMaterial">Materiał</th>
                <th className="responseThQuantity">Ilość</th>
                <th className="responseThMeasure">Wymiar</th>
                {isPdf && (
                  <>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <th className="responseThSaws" />
                    <th className="responseThD">MAGAZYN</th>
                  </>
                )}
              </tr>
            </thead>
            {values.width <= 5800 && (
              <tbody>
                <tr>
                  <td>1</td>
                  <td>{response.productionMaterials.gearRail.gearRail}</td>
                  <td>Listwa zębata 8x30x1005</td>
                  <td>
                    {response.productionMaterials.gearRail.gearRailAmount}
                    {' '}
                    szt
                  </td>
                  <td>
                    1005 mm
                  </td>
                  {isPdf && (
                    <>
                      <td />
                      <td className="tdCenter">
                        <div className="pdfSquare" />
                      </td>
                    </>
                  )}
                </tr>
              </tbody>
            )}
            {values.width > 5800 && (
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Listwa zębata</td>
                  <td>Listwa zębata 8x30x1005</td>
                  <td>
                    {response.productionMaterials.gearRail.gearRailAmount}
                    {' '}
                    szt
                  </td>
                  <td>
                    1005 mm
                  </td>
                  {isPdf && (
                    <>
                      <td />
                      <td className="tdCenter">
                        <div className="pdfSquare" />
                      </td>
                    </>
                  )}
                </tr>
                {(response.productionMaterials.gearRail.cutGearRailAmount !== 'NIE DOTYCZY' && response.productionMaterials.gearRail.gearRail !== '#VALUE!') && (
                  <tr>
                    <td>2</td>
                    <td>Listwa zębata docinana</td>
                    <td>Listwa zębata 8x30x1005</td>
                    <td>
                      {response.productionMaterials.gearRail.cutGearRailAmount}
                    </td>
                    <td>
                      DOCINANA
                    </td>
                    {isPdf && (
                      <>
                        <td />
                        <td className="tdCenter">
                          <div className="pdfSquare" />
                        </td>
                      </>
                    )}
                  </tr>
                )}
              </tbody>
            )}
          </table>
        );
      }
      return null;
    } return null;
  };

  const xps = Object.keys(response.productionMaterials.calculation).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.calculation[key].name}</td>
      <td>{response.productionMaterials.calculation[key].material}</td>
      <td>
        {response.productionMaterials.calculation[key].amount.value}
        {' '}
        {response.productionMaterials.calculation[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.calculation[key].size.valueX}
        {' '}
        {response.productionMaterials.calculation[key].size.unit}
      </td>
      <td>
        {response.productionMaterials.calculation[key].size?.valueY}
      </td>
      {isPdf && (
      <>
        <td />
        <td className="tdCenter">
          <div className="pdfSquare" />
        </td>
      </>
      )}
    </tr>
  ));

  let xpsError = '';
  if ('error' in response.productionMaterials) {
    xpsError = `Wystąpiły błędy w projekcie: ${response.productionMaterials.error}`;
  }
  const xpsFullError = xpsError;

  return (
    <div>
      {(values.width <= 5800 && response.productionMaterials.info.gateIsRectangle && response.productionMaterials.info.gateIsRectangle === RECTANGLE_YES)
        && (
          <p>
            <b>Czy brama prostokątna?</b>
            {' '}
            {response.productionMaterials.info.gateIsRectangle}
          </p>
        )}
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>KĄTY</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">KĄTY</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {angles}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>LASER - MATERIAŁY DO WYPALENIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">3D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {laser}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>PIŁA - MATERIAŁY DO WYCIĘCIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThSaws">PIŁA</th>
                <th className="responseThD">2D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {saw}
          {response.productionMaterials?.gearRail && response.productionMaterials.gearRail?.cutGearRailAmount && (
            <tr>
              <td>{saw.length + 1}</td>
              <td>{response.productionMaterials.gearRail.cutGearRail}</td>
              <td>Listwa zębata 8x30x1005</td>
              <td>
                {response.productionMaterials.gearRail.cutGearRailAmount}
                {' '}
                szt
              </td>
              <td>
                {response.productionMaterials.gearRail.cutGearRailSize}
                {' '}
                mm
              </td>
              {isPdf && (
                <>
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
              )}
            </tr>
          )}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>GALANTERIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThSaws">GALANTERIA</th>
                <th>{' '}</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {gallantry}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>KAZIMIERZ XPS</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Szerokość płyty</th>
            <th className="responseThMeasure">Wysokość płyty</th>
            {isPdf && (
              <>
                <th className="responseThSaws">KAZIMIERZ XPS</th>
                <th className="responseThD">2D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {xps}
        </tbody>
      </table>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <th>
          <th>
            {xpsFullError}
          </th>
        </th>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>KONSTRUKCJA XPS</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            {isPdf && (
              <>
                <th className="responseThSaws">KONSTRUKCJA XPS</th>
                <th className="responseThD">2D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {Object.keys(response.productionMaterials.construction).map((key, index) => (
            <tr key={key}>
              <td>{index + 1}</td>
              <td>{response.productionMaterials.construction[key]}</td>
              {isPdf && (
              <>
                <td />
                <td className="tdCenter">
                  <div className="pdfSquare" />
                </td>
              </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {isPdf && (values.width <= 5800) && (
        <>
          <div>
            <p>
              Ilość pól z modułem:
              {' '}
              {response.productionMaterials.info.areaAmount.value}
              {' '}
              szt
            </p>
          </div>
          <div>
            <p>
              Wysokość pola [H]:
              {' '}
              {response.productionMaterials.info.areaHeight.value}
              {' '}
              mm
            </p>
          </div>
          <div>
            <p>
              Szerokość pola [L]:
              {' '}
              {response.productionMaterials.info.areaWidth.value}
              {' '}
              mm
            </p>
          </div>
          <div className="illustration">
            <div className="illustrationSquareGate" />
            <p className="illustrationHeightSymbolGate">H</p>
            <p className="illustrationWidthSymbolGate">L</p>
          </div>
        </>
      )}
      {!isPdf && <GearRail />}
      {!isPdf && <InfoForOffice />}
    </div>
  );
};

GateSelfSupportingResponse.propTypes = {
  response: PropTypes.object.isRequired,
};

export default GateSelfSupportingResponse;
