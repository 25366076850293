/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

const LaserTable = ({ response, isPdf }) => {
  const laser = Object.keys(response.productionMaterials.laser).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.laser[key].name}</td>
      <td>{response.productionMaterials.laser[key].material}</td>
      <td>
        {response.productionMaterials.laser[key].amount.value}
        {' '}
        {response.productionMaterials.laser[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.laser[key].size.value}
        {' '}
        {response.productionMaterials.laser[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  return (
    <>
      {response.productionMaterials?.angles && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>KĄTY</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              <th className="responseThMeasure">Wymiar</th>
              {isPdf && (
                <>
                  <th>{' '}</th>
                  <th className="responseThSaws">KĄTY</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(response.productionMaterials.angles).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{response.productionMaterials.angles[key].name}</td>
                <td>{response.productionMaterials.angles[key].material}</td>
                <td>
                  {response.productionMaterials.angles[key].amount.value}
                  {' '}
                  {response.productionMaterials.angles[key].amount.unit}
                </td>
                <td>
                  {response.productionMaterials.angles[key].size.value}
                  {' '}
                  {response.productionMaterials.angles[key].size.unit}
                </td>
                {isPdf && (
                  <>
                    <td />
                    <td className="tdCenter">
                      <div className="pdfSquare" />
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>LASER - MATERIAŁY DO WYPALENIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">3D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {laser}
        </tbody>
      </table>
    </>
  );
};

const GateGatewayResponse = ({ response, values, isPdf }) => {
  const saw = Object.keys(response.productionMaterials.saw).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.saw[key].name}</td>
      <td>{response.productionMaterials.saw[key].material}</td>
      <td>
        {response.productionMaterials.saw[key].amount.value}
        {' '}
        {response.productionMaterials.saw[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.saw[key].size.value}
        {' '}
        {response.productionMaterials.saw[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  let gallantry = null;
  if ((parseInt(values.leftWingWidth) >= 3000 || parseInt(values.rightWingWidth) >= 3000)) {
    gallantry = Object.keys(response.productionMaterials.gallantry).map((key, index) => (
      <tr key={key}>
        <td>{index + 1}</td>
        <td>{response.productionMaterials.gallantry[key].name}</td>
        <td>{response.productionMaterials.gallantry[key].material}</td>
        <td>
          {response.productionMaterials.gallantry[key].amount.value}
          {' '}
          {response.productionMaterials.gallantry[key].amount.unit}
        </td>
        <td>
          {response.productionMaterials.gallantry[key].size.value}
          {' '}
          {response.productionMaterials.gallantry[key].size.unit}
        </td>
        {isPdf && (
          <>
            <td className="tdCenter">
              <div className="pdfSquare" />
            </td>
            <td />
          </>
        )}
      </tr>
    ));
  }

  return (
    <div>
      <LaserTable response={response} isPdf={isPdf} />
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>PIŁA - MATERIAŁY DO WYCIĘCIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThSaws">PIŁA</th>
                <th className="responseThD">2D</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {saw}
        </tbody>
      </table>
      {(parseInt(values.leftWingWidth) >= 3000 || parseInt(values.rightWingWidth) >= 3000) && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>GALANTERIA</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              <th className="responseThMeasure">Wymiar</th>
              {isPdf && (
                <>
                  <th className="responseThSaws">GALANTERIA</th>
                  <th>{' '}</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {gallantry}
          </tbody>
        </table>
      )}
    </div>
  );
};

GateGatewayResponse.propTypes = {
  response: PropTypes.object.isRequired,
};

export default GateGatewayResponse;
