import React from 'react';
import PropTypes from 'prop-types';

export const PS004bSpanSecondPagePdf = () => (
  <div> </div>
);

const SpanResponse = ({ response, isPdf }) => {
  const angles = Object.keys(response.productionMaterials.angles).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.angles[key].name}</td>
      <td>{response.productionMaterials.angles[key].material}</td>
      <td>
        {response.productionMaterials.angles[key].amount.value}
        {' '}
        {response.productionMaterials.angles[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.angles[key].size.value}
        {' '}
        {response.productionMaterials.angles[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const studding = Object.keys(response.productionMaterials.studding).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.studding[key].name}</td>
      <td>{response.productionMaterials.studding[key].material}</td>
      <td>
        {response.productionMaterials.studding[key].amount.value}
        {' '}
        {response.productionMaterials.studding[key].amount.unit}
      </td>
      {isPdf && (
        <>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
          <td />
        </>
      )}
    </tr>
  ));

  const railing = Object.keys(response.productionMaterials.railing).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.railing[key].name}</td>
      <td>{response.productionMaterials.railing[key].material}</td>
      <td>
        {response.productionMaterials.railing[key].amount.value}
        {' '}
        {response.productionMaterials.railing[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.railing[key].size.value}
        {' '}
        {response.productionMaterials.railing[key].size.value ? response.productionMaterials.railing[key].size.unit : ''}
      </td>
      {isPdf && (
        <>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
          <td />
        </>
      )}
    </tr>
  ));

  const gallantry1 = Object.keys(response.productionMaterials.gallantry1).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.gallantry1[key].name}</td>
      <td>{response.productionMaterials.gallantry1[key].material}</td>
      <td>
        {response.productionMaterials.gallantry1[key].amount.value}
        {' '}
        {response.productionMaterials.gallantry1[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.gallantry1[key].size.value}
        {' '}
        {response.productionMaterials.gallantry1[key].size.unit}
      </td>
      {isPdf && (
      <>
        <td />
        <td className="tdCenter">
          <div className="pdfSquare" />
        </td>
      </>
      )}
    </tr>
  ));

  const gallantry2 = Object.keys(response.productionMaterials.gallantry2).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.gallantry2[key].name}</td>
      <td>{response.productionMaterials.gallantry2[key].material}</td>
      <td>
        {response.productionMaterials.gallantry2[key].amount.value}
        {' '}
        {response.productionMaterials.gallantry2[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.gallantry2[key].size.value}
        {' '}
        {response.productionMaterials.gallantry2[key].size.unit}
      </td>
      {isPdf && (
      <>
        <td />
        <td className="tdCenter">
          <div className="pdfSquare" />
        </td>
      </>
      )}
    </tr>
  ));

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>KĄTY</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThSaws" aria-label="extraColumn" />
                <th className="responseThD">KĄTY</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {angles}
        </tbody>
      </table>
      {response.productionMaterials?.saw && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? '7' : '5'}>PIŁA - MATERIAŁY DO WYCIĘCIA</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              <th className="responseThMeasure">Wymiar</th>
              {isPdf && (
                <>
                  <th className="responseThSaws">PIŁA</th>
                  <th className="responseThD">2D</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(response.productionMaterials.saw).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{response.productionMaterials.saw[key].name}</td>
                <td>{response.productionMaterials.saw[key].material}</td>
                <td>
                  {response.productionMaterials.saw[key].amount.value}
                  {' '}
                  {response.productionMaterials.saw[key].amount.unit}
                </td>
                <td>
                  {response.productionMaterials.saw[key].size.value}
                  {' '}
                  {response.productionMaterials.saw[key].size.unit}
                </td>
                {isPdf && (
                  <>
                    <td className="tdCenter">
                      <div className="pdfSquare" />
                    </td>
                    <td className="tdCenter">
                      <div className="pdfSquare" />
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {response.productionMaterials?.gallantry && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>GALANTERIA</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              <th className="responseThMeasure">Wymiar</th>
              {isPdf && (
                <>
                  <th className="responseThSaws">GALANTERIA</th>
                  <th>{' '}</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(response.productionMaterials.gallantry).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{response.productionMaterials.gallantry[key].name}</td>
                <td>{response.productionMaterials.gallantry[key].material}</td>
                <td>
                  {response.productionMaterials.gallantry[key].amount.value}
                  {' '}
                  {response.productionMaterials.gallantry[key].amount.unit}
                </td>
                <td>
                  {response.productionMaterials.gallantry[key].size.value}
                  {' '}
                  {response.productionMaterials.gallantry[key].size.unit}
                </td>
                {isPdf && (
                  <>
                    <td className="tdCenter">
                      <div className="pdfSquare" />
                    </td>
                    <td />
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '6' : '4'}>NABIJANIE</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            {isPdf && (
              <>
                <th className="responseThD">NABIJANIE</th>
                <th className="responseThSaws" aria-label="extraColumn" />
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {studding}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>SZTACHETY</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th className="responseThD">SZTACHETY</th>
                <th className="responseThSaws" aria-label="extraColumn" />
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {railing}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>GALANTERIA - ROZCINARKA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">GALANTERIA</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {gallantry1}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>GALANTERIA - ZAGINARKA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">GALANTERIA</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {gallantry2}
        </tbody>
      </table>
      {response.productionMaterials?.storage && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>MAGAZYN - OSPRZĘT</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">OSPRZĘT</th>
              </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(response.productionMaterials.storage).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{response.productionMaterials.storage[key].name}</td>
                <td>{response.productionMaterials.storage[key].material}</td>
                <td>
                  {response.productionMaterials.storage[key].amount.value}
                  {' '}
                  {response.productionMaterials.storage[key].amount.unit}
                </td>
                {isPdf && (
                <>
                  <td />
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <table className="table">
        <thead>
          <tr>
            <th>OTWORY POD SZTACHETĘ</th>
          </tr>
        </thead>
      </table>
      <p>
        <b>{response.productionMaterials.railHoles.holeOne.name}</b>
        {': '}
        {response.productionMaterials.railHoles.holeOne.size.value}
        {' '}
        mm
        {response.productionMaterials.railHoles.holeTwo.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>{response.productionMaterials.railHoles.holeTwo.name}</b>
            {': '}
            {response.productionMaterials.railHoles.holeTwo.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.railHoles.holeThree.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>{response.productionMaterials.railHoles.holeThree.name}</b>
            {': '}
            {response.productionMaterials.railHoles.holeThree.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.railHoles.holeFour.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>{response.productionMaterials.railHoles.holeFour.name}</b>
            {': '}
            {response.productionMaterials.railHoles.holeFour.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.railHoles.lastHole.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>{response.productionMaterials.railHoles.lastHole.name}</b>
            {': '}
            {response.productionMaterials.railHoles.lastHole.size.value}
            {' '}
            mm
          </>
        )}
      </p>
      <table className="table">
        <thead>
          <tr>
            <th>OTWORY W CEOWNIKU MONTAŻOWYM</th>
          </tr>
        </thead>
      </table>
      <p>
        <b>{response.productionMaterials.channelHoles.holeOne.name}</b>
        {': '}
        {response.productionMaterials.channelHoles.holeOne.size.value}
        {' '}
        mm
        {response.productionMaterials.channelHoles.holeTwo.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>{response.productionMaterials.channelHoles.holeTwo.name}</b>
            {': '}
            {response.productionMaterials.channelHoles.holeTwo.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.channelHoles.holeThree.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>{response.productionMaterials.channelHoles.holeThree.name}</b>
            {': '}
            {response.productionMaterials.channelHoles.holeThree.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.channelHoles.holeFour.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>{response.productionMaterials.channelHoles.holeFour.name}</b>
            {': '}
            {response.productionMaterials.channelHoles.holeFour.size.value}
            {' '}
            mm
          </>
        )}
      </p>
      <table className="table">
        <thead>
          <tr>
            <th>ZACINKI W KĄTOWNIKU ZAKRYWAJĄCYM</th>
          </tr>
        </thead>
      </table>
      <p>
        <b>{response.productionMaterials.rodHoles.holeOne.name}</b>
        {': '}
        {response.productionMaterials.rodHoles.holeOne.size.value}
        {' '}
        mm
        {response.productionMaterials.rodHoles.holeTwo.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>{response.productionMaterials.rodHoles.holeTwo.name}</b>
            {': '}
            {response.productionMaterials.rodHoles.holeTwo.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.rodHoles.holeThree.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>{response.productionMaterials.rodHoles.holeThree.name}</b>
            {': '}
            {response.productionMaterials.rodHoles.holeThree.size.value}
            {' '}
            mm
          </>
        )}
        {response.productionMaterials.rodHoles.holeFour.name !== 'Nie dotyczy' && (
          <>
            {' '}
            |
            {' '}
            <b>{response.productionMaterials.rodHoles.holeFour.name}</b>
            {': '}
            {response.productionMaterials.rodHoles.holeFour.size.value}
            {' '}
            mm
          </>
        )}
      </p>
    </div>
  );
};

SpanResponse.propTypes = {
  response: PropTypes.object.isRequired,
};

export default SpanResponse;
