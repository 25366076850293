import React from 'react';
import PropTypes from 'prop-types';

const GateSelfSupportingResponse = ({ values, response, isPdf }) => {
  const angles = Object.keys(response.productionMaterials.angles).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.angles[key].name}</td>
      <td>{response.productionMaterials.angles[key].material}</td>
      <td>
        {response.productionMaterials.angles[key].amount.value}
        {' '}
        {response.productionMaterials.angles[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.angles[key].size.value}
        {' '}
        {response.productionMaterials.angles[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const laser = Object.keys(response.productionMaterials.laser).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.laser[key].name}</td>
      <td>{response.productionMaterials.laser[key].material}</td>
      <td>
        {response.productionMaterials.laser[key].amount.value}
        {' '}
        {response.productionMaterials.laser[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.laser[key].size.value}
        {' '}
        {response.productionMaterials.laser[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td />
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const saw = Object.keys(response.productionMaterials.saw).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.saw[key].name}</td>
      <td>{response.productionMaterials.saw[key].material}</td>
      <td>
        {response.productionMaterials.saw[key].amount.value}
        {' '}
        {response.productionMaterials.saw[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.saw[key].size.value}
        {' '}
        {response.productionMaterials.saw[key].size.unit}
      </td>
      {isPdf && (
        <>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
          <td className="tdCenter">
            <div className="pdfSquare" />
          </td>
        </>
      )}
    </tr>
  ));

  const gallantry1 = Object.keys(response.productionMaterials.gallantry1).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.gallantry1[key].name}</td>
      <td>{response.productionMaterials.gallantry1[key].material}</td>
      <td>
        {response.productionMaterials.gallantry1[key].amount.value}
        {' '}
        {response.productionMaterials.gallantry1[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.gallantry1[key].size.value}
        {' '}
        {response.productionMaterials.gallantry1[key].size.unit}
      </td>
      {isPdf && (
      <>
        <td />
        <td className="tdCenter">
          <div className="pdfSquare" />
        </td>
      </>
      )}
    </tr>
  ));

  const gallantry2 = Object.keys(response.productionMaterials.gallantry2).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.gallantry2[key].name}</td>
      <td>{response.productionMaterials.gallantry2[key].material}</td>
      <td>
        {response.productionMaterials.gallantry2[key].amount.value}
        {' '}
        {response.productionMaterials.gallantry2[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.gallantry2[key].size.value}
        {' '}
        {response.productionMaterials.gallantry2[key].size.unit}
      </td>
      {isPdf && (
      <>
        <td />
        <td className="tdCenter">
          <div className="pdfSquare" />
        </td>
      </>
      )}
    </tr>
  ));

  const GearRail = () => {
    if (response.productionMaterials?.gearRail) {
      if (response.productionMaterials.gearRail.gearRailAmount !== 'NIE DOTYCZY' && response.productionMaterials.gearRail.gearRail !== '#VALUE!' && response.productionMaterials.gearRail.gearRailAmount > 0) {
        return (
          <table className="table">
            <thead>
              <tr>
                <th colSpan={isPdf ? '7' : '5'}>MAGAZYN - ZBROJENIE</th>
              </tr>
              <tr>
                <th className="responseThSingular">Lp.</th>
                <th className="responseThName">Nazwa</th>
                <th className="responseThMaterial">Materiał</th>
                <th className="responseThQuantity">Ilość</th>
                <th className="responseThMeasure">Wymiar</th>
                {isPdf && (
                  <>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <th className="responseThSaws" />
                    <th className="responseThD">MAGAZYN</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>{response.productionMaterials.gearRail.gearRail}</td>
                <td>Listwa zębata 8x30x1005</td>
                <td>
                  {response.productionMaterials.gearRail.gearRailAmount}
                  {' '}
                  szt
                </td>
                <td>
                  1005 mm
                </td>
                {isPdf && (
                  <>
                    <td />
                    <td className="tdCenter">
                      <div className="pdfSquare" />
                    </td>
                  </>
                )}
              </tr>
            </tbody>
          </table>
        );
      }
      return null;
    } return null;
  };

  return (
    <div>
      {(values.width <= 5800 && response.productionMaterials.info.gateIsRectangle && response.productionMaterials.info.gateIsRectangle === 'TAK')
        && (
          <p>
            <b>Czy brama prostokątna?</b>
            {' '}
            {response.productionMaterials.info.gateIsRectangle}
          </p>
        )}
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>KĄTY</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
            <>
              <th>{' '}</th>
              <th className="responseThSaws">KĄTY</th>
            </>
            )}
          </tr>
        </thead>
        <tbody>
          {angles}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>LASER - MATERIAŁY DO WYPALENIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
            <>
              <th>{' '}</th>
              <th className="responseThSaws">3D</th>
            </>
            )}
          </tr>
        </thead>
        <tbody>
          {laser}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? '7' : '5'}>PIŁA - MATERIAŁY DO WYCIĘCIA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
            <>
              <th className="responseThSaws">PIŁA</th>
              <th className="responseThD">2D</th>
            </>
            )}
          </tr>
        </thead>
        <tbody>
          {saw}
          {values.width <= 5800 && response.productionMaterials.gearRail && response.productionMaterials.gearRail?.cutGearRailAmount && (
            <tr>
              <td>{saw.length + 1}</td>
              <td>{response.productionMaterials.gearRail.cutGearRail}</td>
              <td>Listwa zębata 8x30x1005</td>
              <td>
                {response.productionMaterials.gearRail.cutGearRailAmount}
                {' '}
                szt
              </td>
              <td>
                {response.productionMaterials.gearRail.cutGearRailSize}
                {' '}
                mm
              </td>
              {isPdf && (
                <>
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
              )}
            </tr>
          )}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>GALANTERIA - ROZCINARKA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">GALANTERIA</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {gallantry1}
        </tbody>
      </table>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>GALANTERIA - ZAGINARKA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            <th className="responseThMeasure">Wymiar</th>
            {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">GALANTERIA</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {gallantry2}
        </tbody>
      </table>
      {values.width <= 5800 && <GearRail />}
    </div>
  );
};

GateSelfSupportingResponse.propTypes = {
  response: PropTypes.object.isRequired,
};

export default GateSelfSupportingResponse;
