import React from 'react';
import PropTypes from 'prop-types';

export const PP001P46GateSelfSupporting5801SecondPage = ({ form }) => (
  <>
    <p>
      Odstęp od pionu zamkowego:
      {' '}
      {form.response.productionMaterials.welding.spacingFromVerticalFrameLock}
      {' '}
      mm
    </p>
    <p>
      Odstęp od pionu przeciwwagi:
      {' '}
      {form.response.productionMaterials.welding.spacingFromVerticalFrameCounterweight}
      {' '}
      mm
    </p>
    <p>
      Szerokość pierwszego pola:
      {' '}
      {form.response.productionMaterials.welding.areaOneWidth}
      {' '}
      mm
    </p>
    <p>
      Szerokość drugiego pola:
      {' '}
      {form.response.productionMaterials.welding.areaTwoWidth}
      {' '}
      mm
    </p>
    <p>
      Szerokość trzeciego pola:
      {' '}
      {form.response.productionMaterials.welding.areaThreeWidth}
      {' '}
      mm
    </p>
    <p>
      Szerokość czwartego pola:
      {' '}
      {form.response.productionMaterials.welding.areaFourWidth}
      {' '}
      mm
    </p>
  </>
);

const GateSelfSupportingResponse = ({ values, response, isPdf }) => {
  const GearRail = () => {
    if (response.productionMaterials?.gearRail) {
      if (response.productionMaterials.gearRail.gearRailAmount !== 'NIE DOTYCZY' && response.productionMaterials.gearRail.gearRail !== '#VALUE!' && response.productionMaterials.gearRail.gearRailAmount > 0) {
        return (
          <table className="table">
            <thead>
              <tr>
                <th colSpan={isPdf ? '7' : '5'}>MAGAZYN - ZBROJENIE</th>
              </tr>
              <tr>
                <th className="responseThSingular">Lp.</th>
                <th className="responseThName">Nazwa</th>
                <th className="responseThMaterial">Materiał</th>
                <th className="responseThQuantity">Ilość</th>
                <th className="responseThMeasure">Wymiar</th>
                {isPdf && (
                  <>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <th className="responseThSaws" />
                    <th className="responseThD">MAGAZYN</th>
                  </>
                )}
              </tr>
            </thead>
            {values.width <= 5800 && (
              <tbody>
                <tr>
                  <td>1</td>
                  <td>{response.productionMaterials.gearRail.gearRail}</td>
                  <td>Listwa zębata 8x30x1005</td>
                  <td>
                    {response.productionMaterials.gearRail.gearRailAmount}
                    {' '}
                    szt
                  </td>
                  <td>
                    1005 mm
                  </td>
                  {isPdf && (
                    <>
                      <td />
                      <td className="tdCenter">
                        <div className="pdfSquare" />
                      </td>
                    </>
                  )}
                </tr>
              </tbody>
            )}
            {values.width > 5800 && (
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Listwa zębata</td>
                  <td>Listwa zębata 8x30x1005</td>
                  <td>
                    {response.productionMaterials.gearRail.gearRailAmount}
                    {' '}
                    szt
                  </td>
                  <td>
                    1005 mm
                  </td>
                  {isPdf && (
                    <>
                      <td />
                      <td className="tdCenter">
                        <div className="pdfSquare" />
                      </td>
                    </>
                  )}
                </tr>
                {(response.productionMaterials.gearRail.cutGearRailAmount !== 'NIE DOTYCZY' && response.productionMaterials.gearRail.gearRail !== '#VALUE!') && (
                  <tr>
                    <td>2</td>
                    <td>Listwa zębata docinana</td>
                    <td>Listwa zębata 8x30x1005</td>
                    <td>
                      {response.productionMaterials.gearRail.cutGearRailAmount}
                    </td>
                    <td>
                      DOCINANA
                    </td>
                    {isPdf && (
                      <>
                        <td />
                        <td className="tdCenter">
                          <div className="pdfSquare" />
                        </td>
                      </>
                    )}
                  </tr>
                )}
              </tbody>
            )}
          </table>
        );
      }
      return null;
    } return null;
  };

  return (
    <div>
      {(response.productionMaterials?.info?.gateIsRectangle && response.productionMaterials.info.gateIsRectangle === 'TAK')
        && (
          <p>
            <b>Czy brama prostokątna?</b>
            {' '}
            {response.productionMaterials.info.gateIsRectangle}
          </p>
        )}
      {response.productionMaterials?.laser && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>LASER - MATERIAŁY DO WYPALENIA</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              <th className="responseThMeasure">Wymiar</th>
              {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">3D</th>
              </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(response.productionMaterials.laser).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{response.productionMaterials.laser[key].name}</td>
                <td>{response.productionMaterials.laser[key].material}</td>
                <td>
                  {response.productionMaterials.laser[key].amount.value}
                  {' '}
                  {response.productionMaterials.laser[key].amount.unit}
                </td>
                <td>
                  {response.productionMaterials.laser[key].size.value}
                  {' '}
                  {response.productionMaterials.laser[key].size.unit}
                </td>
                {isPdf && (
                <>
                  <td />
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {response.productionMaterials?.saw && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? '7' : '5'}>PIŁA - MATERIAŁY DO WYCIĘCIA</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              <th className="responseThMeasure">Wymiar</th>
              {isPdf && (
              <>
                <th className="responseThSaws">PIŁA</th>
                <th className="responseThD">2D</th>
              </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(response.productionMaterials.saw).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{response.productionMaterials.saw[key].name}</td>
                <td>{response.productionMaterials.saw[key].material}</td>
                <td>
                  {response.productionMaterials.saw[key].amount.value}
                  {' '}
                  {response.productionMaterials.saw[key].amount.unit}
                </td>
                <td>
                  {response.productionMaterials.saw[key].size.value}
                  {' '}
                  {response.productionMaterials.saw[key].size.unit}
                </td>
                {isPdf && (
                <>
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <GearRail />
      {values.width <= 5800 && (
        <>
          <p>
            Odstęp od pionu zamkowego:
            {' '}
            {response.productionMaterials.welding.spacingFromVerticalFrameLock}
            {' '}
            mm
          </p>
          <p>
            Odstęp od pionu przeciwwagi:
            {' '}
            {response.productionMaterials.welding.spacingFromVerticalFrameCounterweight}
            {' '}
            mm
          </p>
        </>
      )}
      {values.width > 5800 && !isPdf && (
        <>
          <p>
            Odstęp od pionu zamkowego:
            {' '}
            {response.productionMaterials.welding.spacingFromVerticalFrameLock}
            {' '}
            mm
          </p>
          <p>
            Odstęp od pionu przeciwwagi:
            {' '}
            {response.productionMaterials.welding.spacingFromVerticalFrameCounterweight}
            {' '}
            mm
          </p>
          <p>
            Szerokość pierwszego pola:
            {' '}
            {response.productionMaterials.welding.areaOneWidth}
            {' '}
            mm
          </p>
          <p>
            Szerokość drugiego pola:
            {' '}
            {response.productionMaterials.welding.areaTwoWidth}
            {' '}
            mm
          </p>
          <p>
            Szerokość trzeciego pola:
            {' '}
            {response.productionMaterials.welding.areaThreeWidth}
            {' '}
            mm
          </p>
          {response.productionMaterials.welding.areaFourWidth !== 'NIE DOTYCZY' && (
            <p>
              Szerokość czwartego pola:
              {' '}
              {response.productionMaterials.welding.areaFourWidth}
              {' '}
              mm
            </p>
          )}
        </>
      )}
    </div>
  );
};

GateSelfSupportingResponse.propTypes = {
  response: PropTypes.object.isRequired,
};

export default GateSelfSupportingResponse;
