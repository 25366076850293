import React from 'react';
import PropTypes from 'prop-types';

const SpanResponse = ({ response, isPdf }) => {
  const saw = Object.keys(response.productionMaterials.saw).map((key, index) => (
    <tr key={key}>
      <td>{index + 1}</td>
      <td>{response.productionMaterials.saw[key].name}</td>
      <td>{response.productionMaterials.saw[key].material}</td>
      <td>
        {response.productionMaterials.saw[key].amount.value}
        {' '}
        {response.productionMaterials.saw[key].amount.unit}
      </td>
      <td>
        {response.productionMaterials.saw[key].size.value}
        {' '}
        {response.productionMaterials.saw[key].size.unit}
      </td>
      {isPdf && (
      <>
        <td />
        <td className="tdCenter">
          <div className="pdfSquare" />
        </td>
      </>
      )}
    </tr>
  ));

  return (
    <div>
      {response.productionMaterials?.saw && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? '7' : '5'}>PIŁA - MATERIAŁY DO WYCIĘCIA</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              <th className="responseThMeasure">Wymiar</th>
              {isPdf && (
                <>
                  <th className="responseThSaws">PIŁA</th>
                  <th className="responseThD">2D</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {saw}
          </tbody>
        </table>
      )}
      {response.productionMaterials?.gallantry && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>GALANTERIA</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              <th className="responseThMeasure">Wymiar</th>
              {isPdf && (
                <>
                  <th className="responseThSaws">GALANTERIA</th>
                  <th>{' '}</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(response.productionMaterials.gallantry).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{response.productionMaterials.gallantry[key].name}</td>
                <td>{response.productionMaterials.gallantry[key].material}</td>
                <td>
                  {response.productionMaterials.gallantry[key].amount.value}
                  {' '}
                  {response.productionMaterials.gallantry[key].amount.unit}
                </td>
                <td>
                  {response.productionMaterials.gallantry[key].size.value}
                  {' '}
                  {response.productionMaterials.gallantry[key].size.unit}
                </td>
                {isPdf && (
                  <>
                    <td className="tdCenter">
                      <div className="pdfSquare" />
                    </td>
                    <td />
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {response.productionMaterials?.storage && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>MAGAZYN - OSPRZĘT</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">OSPRZĘT</th>
              </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(response.productionMaterials.storage).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{response.productionMaterials.storage[key].name}</td>
                <td>{response.productionMaterials.storage[key].material}</td>
                <td>
                  {response.productionMaterials.storage[key].amount.value}
                  {' '}
                  {response.productionMaterials.storage[key].amount.unit}
                </td>
                {isPdf && (
                <>
                  <td />
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {response.productionMaterials?.storageWrapper && (
      <table className="table">
        <thead>
          <tr>
            <th colSpan={isPdf ? 7 : 5}>MAGAZYN - OWIJARKA</th>
          </tr>
          <tr>
            <th className="responseThSingular">Lp.</th>
            <th className="responseThName">Nazwa</th>
            <th className="responseThMaterial">Materiał</th>
            <th className="responseThQuantity">Ilość</th>
            {isPdf && (
              <>
                <th>{' '}</th>
                <th className="responseThSaws">OWIJARKA</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {Object.keys(response.productionMaterials.storageWrapper).map((key, index) => (
            <tr key={key}>
              <td>{index + 1}</td>
              <td>{response.productionMaterials.storageWrapper[key].name}</td>
              <td>{response.productionMaterials.storageWrapper[key].material}</td>
              <td>
                {response.productionMaterials.storageWrapper[key].amount.value}
                {' '}
                {response.productionMaterials.storageWrapper[key].amount.unit}
              </td>
              {isPdf && (
                <>
                  <td />
                  <td className="tdCenter">
                    <div className="pdfSquare" />
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      )}
      {response.productionMaterials?.studding && (
        <table className="table">
          <thead>
            <tr>
              <th colSpan={isPdf ? 7 : 5}>NABIJANIE</th>
            </tr>
            <tr>
              <th className="responseThSingular">Lp.</th>
              <th className="responseThName">Nazwa</th>
              <th className="responseThMaterial">Materiał</th>
              <th className="responseThQuantity">Ilość</th>
              {/* <th className="responseThMeasure">Wymiar</th> */}
              {isPdf && (
                <>
                  <th>{' '}</th>
                  <th className="responseThSaws">NABIJANIE</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(response.productionMaterials.studding).map((key, index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{response.productionMaterials.studding[key].name}</td>
                <td>{response.productionMaterials.studding[key].material}</td>
                <td>
                  {response.productionMaterials.studding[key].amount.value}
                  {' '}
                  {response.productionMaterials.studding[key].amount.unit}
                </td>
                {/* <td>
                  {response.productionMaterials.studding[key].size.value}
                  {' '}
                  {response.productionMaterials.studding[key].size.value === '' ? null : response.productionMaterials.studding[key].size.unit}
                </td> */}
                {isPdf && (
                  <>
                    <td />
                    <td className="tdCenter">
                      <div className="pdfSquare" />
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

SpanResponse.propTypes = {
  response: PropTypes.object.isRequired,
};

export default SpanResponse;
